@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fg:hover{
  color: #2B44FF;
}
.tColor{
  color: #2B44FF;
}
.bgColor{
  background-color: #2B44FF;
  color: white;
  border: #2B44FF;
}
.sidebarHead{
  font-family: 'Poppins', sans-serif !important;
  color:#66788A;
 font-weight: 800;
}
.sidebarData{
  color:#425A70;
}
.sidebarCard{
  color:#0a0a0a;
}

.home{
  width:calc(100% - 280px);
  font-family: 'Poppins', sans-serif !important;
}

.red::-webkit-progress-bar {
  background: rgb(182, 180, 180);
  border: 1px solid rgb(182, 180, 180);;
  border-radius: 5px;
  width:115px;
  height:10px
 }  
.caseDetails::-webkit-progress-bar {
  background: rgb(182, 180, 180);
  border: 1px solid rgb(182, 180, 180);;
  border-radius: 5px;
  height:10px;
  /* width: '-webkit-fill-available'; */
 }  

 progress::-webkit-progress-value {
  border: 0;
  border-radius: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.active {
  /* ACTIVE ROUTE LINK */
  color: #2B44FF !important;
  font-weight: bold;
}

.active svg {
  color: #2B44FF;
}
.misC:hover{
  color: #2B44FF;
}


/* SCROLLBAR CSS */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #BDBDBD; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8AACC8;
}

.sidebar-content {
  height: calc(100% - 64px);
}

